import baseApi from "./base-api";

/**
 * @param {Object} params
 * @param {string} params.search - search text
 * @param {number} params.page
 * @param {number} params.limit
 * @param {string[]} params.organizationIds
 * @param {string[]} params.teamIds
 * @param {string[]} params.userIds
 * @param {import('../typedefs').PlatformFlow} params.platformFlow
 * @param {string} params.dateRangeStart
 * @param {string} params.dateRangeEnd
 */
function getThankyous(params = {}) {
    return baseApi.getWithParams("thank-yous", params);
}

function getDeletedThankyous(params = {}) {
    return baseApi.getWithParams("thank-yous/deleted-thankyous", params);
}

/**
 * @param {string} thankYouId
 */
function getThankYouById(thankYouId) {
    return baseApi.get(`thank-yous/thankyouById/${thankYouId}`);
}

/**
 * @param {Object} params
 * @param {string[]} params.organizationIds
 * @param {string[]} params.teamIds
 * @param {import('../typedefs').PlatformFlow} params.platformFlow
 * @param {string} params.dateRangeStart
 * @param {string} params.dateRangeEnd
 */
function downloadAsCsv(params = {}) {
    return baseApi.getWithParams("thank-yous/download-csv", params);
}

function getDraftThankyous({ pageNumber, pageSize, isArchived } = {}) {
    return baseApi.getWithParams("thank-yous/draft-thankyou", {
        page: pageNumber,
        limit: pageSize,
        isArchived: isArchived
    });
}

function updateThankyou(thankyouId, data) {
    return baseApi.put(`admin/appreciation/thankyous/${thankyouId}`, data);
}

function updateGift(giftId, data) {
    return baseApi.put(`admin/appreciation/gifts/${giftId}`, data);
}

function getModerationFailedThankyous({ pageNumber, pageSize } = {}) {
    return baseApi.getWithParams("thank-yous/moderationFailedThankyou", {
        page: pageNumber,
        limit: pageSize
    });
}

function sendMultipleThankyou(data) {
    return baseApi.post("admin/appreciation/send-thank-you/multiple", data);
}

function getSharedThankyouDetailsByTypeAndId(type, id) {
    return  baseApi.getWithParams("admin/appreciation/thankyou/list/share", {
        type: type,
        id: id
    });
}

function archiveDraftThankyous({ draftThankyouId, isArchived } = {}) {
    return baseApi.deleteWithParams("thank-yous/draft-thankyou/updateArchivedStatus", {
        draftThankyouId: draftThankyouId,
        isArchived: isArchived
    });
}

function deleteDraftThankyous(draftThankyouId, reason) {
    return baseApi.delete(`thank-yous/draft-thankyou/${draftThankyouId}/reason/${reason}`);
}

function releaseDraftThankyou(data) {
    return baseApi.put("thank-yous/draft-thankyou/release", data);
}

function deleteThankyou(thankyouId, deleteReason) {
    return baseApi.delete(`admin/appreciation/thankyou/${thankyouId}/reason/${deleteReason}`);
}

function deleteModerationFailedThankyou(thankyouId, reason) {
    return baseApi.delete(`thank-yous/moderationFailedThankyou/${thankyouId}/reason/${reason}`);
}

const thankYouService = {
    getThankyous: getThankyous,
    getThankYouById: getThankYouById,
    downloadAsCsv: downloadAsCsv,
    getDraftThankyous: getDraftThankyous,
    deleteDraftThankyous: deleteDraftThankyous,
    releaseDraftThankyou: releaseDraftThankyou,
    sendMultipleThankyou: sendMultipleThankyou,
    deleteThankyou: deleteThankyou,
    getModerationFailedThankyous: getModerationFailedThankyous,
    deleteModerationFailedThankyou: deleteModerationFailedThankyou,
    getSharedThankyouDetailsByTypeAndId: getSharedThankyouDetailsByTypeAndId,
    updateThankyou: updateThankyou,
    updateGift: updateGift,
    getDeletedThankyous: getDeletedThankyous
    
};

export default thankYouService;
