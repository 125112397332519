import { createPortal } from "react-dom";
import './Modal.css';
import { ThankyouDeleteReason } from '../../../common/enums/thankyou-delete-reason';
import deleteImg from "../../../assets/Images/delete.png";

/**
 * @param {Object} props
 * @param {string} props.text
 * @param {"confirm" | "delete"} props.type
 * @param {string} props.imgSrc
 * @param {string} props.confirmBtnText
 * @param {boolean} props.showImg
 * @param {string} props.onConfirm
 * @param {string} props.
 * @param {string} props.confirmStyle
 */
export function DeleteConfirmationModalWithReason({
    text,
    type = "confirm",
    imgSrc,
    confirmBtnText = "Confirm",
    showImg = true,
    onConfirm,
    confirmStyle = {},
    onCancel,
    handleDeleteInputChange
}) {
    return createPortal(
        <div className="custom-modal-container">
            <div className="custom-modal-backdrop"></div>
            <div className="custom-modal">
                <div className="confirmation-modal-inner">
                    {showImg && (
                        <div className="d-flex justify-content-center mb-4">
                            {imgSrc && <img src={imgSrc} alt="" />}

                            {/* add default confirm image */}
                            {(!imgSrc && type === "confirm") && <img src={""} alt="" />}

                            {(!imgSrc && type === "delete") && <img src={deleteImg} alt="" />}
                        </div>
                    )}
                    <h4>{text}</h4>
                    <div>
                        Reason:
                        <select
                            name="deleteReason"
                            className="form-select cstm-category mr-1"
                            value={Object.keys(ThankyouDeleteReason)[0]}
                            onChange={handleDeleteInputChange}
                        >
                            {Object.entries(ThankyouDeleteReason).map(([key, reason]) => (
                                <option key={key} value={key}>
                                    {reason}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="mt-4">
                        <button
                            type="button"
                            style={{ ...confirmStyle }}
                            className="mr-3 cstm-btn cstn-discard"
                            onClick={onConfirm}
                        >
                            {confirmBtnText}
                        </button>
                        <button
                            type="button"
                            className="cstm-btn2 cstn-discard"
                            onClick={onCancel}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>,
        document.getElementById("confirmation-modal-portal")
    );
}