import { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SidebarLayoutContent } from '../../shared/components/SidebarLayoutContent';
import thankYouService from '../../Services/thank-you.service';
import { ThankyouDeleteReason } from '../../common/enums/thankyou-delete-reason';
import deleteImg from "../../assets/Images/delete.png";
import { Pagination } from '../shared/Pagination';
import { formatDateForTable } from '../../common/utils/date.util';
import { downloadCsvFromData, generateCsvFileName } from '../../common/utils/csv.util';
import { useDebounceSearchTextFn } from '../../common/hooks/use-debounce.hook';
import { useLoaderStore } from '../../stores/loader.store';
import { Modal } from '../../shared/components/Modal/Modal';
import { ThankYouListFilters } from '../ThankYou/ThankYouListFilters';
import { createSearchParamsFromListState, getListStateFromSearchParams } from '../../utils/list-state.util';
import { DEFAULT_PAGE_SIZE } from '../../common/constants/pagination.constant';
import { APP_ROUTES } from '../../common/app-routes';
import { createBreadcrumb, useSetBreadcrumbs } from '../../shared/hooks/use-set-breadcrumbs.hook';


export function DeletedThankYouList() {
    const setShowLoader = useLoaderStore((state) => state.setShowLoader);
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();
    const [thankYous, setThankYous] = useState([]);
    const [resultsCount, setResultsCount] = useState(0);
    const state = useMemo(() => getThankYouListStateFromSearchParams(searchParams), [searchParams]);
    const filters = state.filters;
    const pagination = { pageNumber: state.pageNumber, pageSize: DEFAULT_PAGE_SIZE };
    const [deleteThankyou, setDeleteThankyou] = useState('');
    const [searchText, setSearchText] = useState(state.searchText);
    const [selectedThankyou, setSelectedThankyou] = useState({});
    const [showFilters, setShowFilters] = useState(false);
    const [deleteReason, setDeleteReason] = useState(Object.keys(ThankyouDeleteReason)[0]);

    useDebounceSearchTextFn(searchText, (debouncedSearchText) => {
        if (debouncedSearchText !== state.searchText) {
            setSearchParams(createThankYouListSearchParams(filters, debouncedSearchText, 1));
        }
    });

    useSetBreadcrumbs([createBreadcrumb(APP_ROUTES.THANK_YOU_LIST)]);

    useEffect(() => {
        getThankYous();
    }, [searchParams]);

    async function getThankYous() {
        setShowLoader(true);

        try {
            const res = await thankYouService.getDeletedThankyous({
                search: state.searchText ? state.searchText.trim() : '',
                page: pagination.pageNumber,
                limit: pagination.pageSize,
                organizationIds: filters.organizationId ? [filters.organizationId] : [],
                teamIds: filters.teamIds,
                platformFlow: filters.platformFlow,
                dateRangeStart: filters.dateFilterType === "range" ? filters.dateRangeStart : undefined,
                dateRangeEnd: filters.dateFilterType === "range" ? filters.dateRangeEnd : undefined,
            });

            if (res.status === 200) {
                setThankYous(res.data.results);
                setResultsCount(res.data.count);
                return;
            }

            toast.error("Couldn't get thank yous");
        } catch (err) {
            toast.error("Couldn't get thank yous");
        } finally {
            setShowLoader(false);
        }
    }

    function onPaginationChange(pageNumber) {
        setSearchParams(createThankYouListSearchParams(filters, state.searchText, pageNumber));
    }

    function getThankYouSenderDetails(thankYou) {
        if (thankYou.platform === 'mobile' && thankYou.createdBy) {
            return {
                firstName: thankYou.createdBy.firstName,
                email: thankYou.createdBy.email,
                phoneNumber: thankYou.createdBy.phoneNumber,
            };
        }

        if (thankYou.platform === 'web' && thankYou.createdUserData) {
            return {
                firstName: thankYou.createdUserData.firstName,
                email: thankYou.createdUserData.email,
                phoneNumber: thankYou.createdUserData.phoneNumber,
            };
        }
    }

    async function handleSendToOrganization() {
        setShowLoader(true);
        try {
            let data = {
                "appreciationType": "thankyou",
                "type": "organization",
                "organizationId": selectedThankyou.organizations[0]._id,
                "appreciationId": selectedThankyou._id
            }
            const res = await thankYouService.sendMultipleThankyou(data);

            if (res.status == 200) {
                toast.success("Successfully sent the thank yous to organization");
                return;
            }
            toast.error("Couldn't send thank yous to organization");
        } catch (err) {
            toast.error("Couldn't send thank yous to organization");
        }
        finally {
            setShowLoader(false);
            handleSendOrganizationCancel();
        }
    }

    async function handleSendToTeam() {
        setShowLoader(true);
        try {
            let data = {
                "appreciationType": "thankyou",
                "type": "team",
                "teamId": selectedThankyou.teams[0]._id,
                "appreciationId": selectedThankyou._id
            }
            const res = await thankYouService.sendMultipleThankyou(data);

            if (res.status == 200) {
                toast.success("Successfully sent the thank yous to team");
                return;
            }
            toast.error("Couldn't send thank yous to team");
        } catch (err) {
            toast.error("Couldn't send thank yous to team");
        }
        finally {
            setShowLoader(false);
            handleSendTeamCancel();
        }
    }

    const handleSendOrganizationCancel = () => {
        setSelectedThankyou(null);
        document.querySelector('.modal-backdrop').remove();
        document.querySelector('#sendOrganizationConfirmationThankyou').classList.remove('show');
    }

    const handleSendTeamCancel = () => {
        setSelectedThankyou(null);
        document.querySelector('.modal-backdrop').remove();
        document.querySelector('#sendTeamConfirmationThankyou').classList.remove('show');
    }

    async function handleDeleteThankyou() {
        document.querySelector(".modal-backdrop").remove();
        document.querySelector("#deleteThankyou").classList.remove("show");
        document.querySelector("#deleteThankyou .btn-close").click();

        try {
            const res = await thankYouService.deleteThankyou(deleteThankyou, deleteReason);

            if (res.status === 200) {
                toast.success("Successfully deleted thank you");
                setThankYous((current) =>
                    current.filter((data) => data._id !== deleteThankyou)
                );
                return;
            }

            toast.error("Couldn't delete thank you");
        }
        catch (err) {
            toast.error("Couldn't delete thank you");
            console.log(err);
        }
    };

    const onClickCancelDeletePopup = () => {
        document.querySelector("#deleteThankyou").classList.remove("show");
        document.querySelector(".modal-backdrop").remove();
    };

    function onApplyFilters(newFilters) {
        setShowFilters(false);
        setSearchParams(createThankYouListSearchParams(newFilters, state.searchText));
    }

    const handleDeleteInputChange = (e) => {
        const { name, value } = e.target;
        setDeleteReason(value);

    };

    return (
        <SidebarLayoutContent headerTitle='Deleted Thank you List'>
            <div className="card border-0 shadow rounded">
                <div className='d-flex justify-content-end align-items-center p-2'>
                    <div className="mr-2">
                        <i className="fi fi-rr-search cstm-search-ro"></i>
                        <input
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            type="text"
                            className="cstm-input-seacrh"
                            placeholder="Search Users"
                        />
                    </div>

                    <button type='button' className='custom-filter-icon-btn mr-2' onClick={() => setShowFilters(true)}>
                        <i className="fi fi-rr-settings-sliders"></i>
                    </button>
                </div>

                <div className="overflow-auto">
                    <table className="table mb-0 table-center">
                        <thead>
                            <tr className="border-top">
                                <th className="border-bottom w-4 cstm-userheading">Message</th>
                                <th className="border-bottom w-4 cstm-userheading">From</th>
                                <th className="border-bottom w-4 cstm-userheading">To</th>
                                <th className="border-bottom w-4 cstm-userheading">Platform</th>
                                <th className="border-bottom w-4 cstm-userheading">Reason</th>
                                <th className="border-bottom w-4 cstm-userheading">Created Date</th>
                            </tr>
                        </thead>

                        <tbody>
                            {thankYous?.map((thankYou) => {
                                const senderDetails = getThankYouSenderDetails(thankYou);

                                return (
                                    <tr
                                        key={thankYou._id}
                                        className="cstm-Tabledesign cstm-usertable-design"
                                    >
                                        <td>{thankYou.description}</td>
                                        <td>
                                            <div>{senderDetails?.firstName}</div>
                                            <div>{senderDetails?.email}</div>
                                            <div>{senderDetails?.phoneNumber}</div>
                                        </td>
                                        <td>
                                            <div>{thankYou.users ? thankYou.users[0]?.firstName : null}</div>
                                            <div>{thankYou.users ? thankYou.users[0]?.email : null}</div>
                                            <div>{thankYou.users ? thankYou.users[0]?.phoneNumber : null}</div>
                                        </td>
                                        <td>{thankYou.flow === "1" ? "Patient Experience" : "Staff Experience"}</td>
                                        <td>{ThankyouDeleteReason[thankYou.deleteReason]}</td>
                                        <td>{thankYou.createdAt ? formatDateForTable(thankYou.createdAt) : null}</td>
                           
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>

                    {!thankYous?.length && (
                        <div className="NoRecord-cstm">No records found!</div>
                    )}
                </div>
            </div>

            <Pagination
                totalRows={resultsCount}
                currentPage={pagination.pageNumber}
                rowsPerPage={pagination.pageSize}
                onPaginationChange={onPaginationChange}
            />

            {/* Organization share confirmation */}
            <div
                className="modal fade"
                id="sendOrganizationConfirmationThankyou"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body py-5">
                            <div className="text-center">
                                <div className="d-flex justify-content-center">
                                    <img src="" alt='' />
                                </div>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    style={{ display: "none" }}>
                                </button>
                                <h4 className="mt-4">
                                    Are you sure you want to share this message with everyone at {selectedThankyou?.organizations ? selectedThankyou?.organizations[0]?.name : ''}
                                </h4>
                                <div className="mt-4">
                                    <button
                                        style={{ backgroundColor: 'red' }}
                                        className="mr-3 cstm-btn cstn-discard"
                                        onClick={handleSendToOrganization}
                                    >
                                        Share
                                    </button>
                                    <button className="cstm-btn2 cstn-discard" onClick={handleSendOrganizationCancel}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Team share confirmation  */}
            <div
                className="modal fade"
                id="sendTeamConfirmationThankyou"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body py-5">
                            <div className="text-center">
                                <div className="d-flex justify-content-center">
                                    <img src="" alt='' />
                                </div>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    style={{ display: "none" }}>
                                </button>
                                <h4 className="mt-4">
                                    Are you sure want to share this message to
                                    <br /> {selectedThankyou?.teams ? selectedThankyou?.teams[0]?.name : ''} team
                                </h4>
                                <div className="mt-4">
                                    <button
                                        className="mr-3 cstm-btn cstn-discard"
                                        onClick={handleSendToTeam}
                                    >
                                        Share
                                    </button>
                                    <button className="cstm-btn2 cstn-discard" onClick={handleSendTeamCancel}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* confirmation dialog delete */}
            <div
                className="modal fade"
                id="deleteThankyou"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body py-5">
                            <div className="text-center">
                                <div className="d-flex justify-content-center">
                                    <img src={deleteImg} alt='' />
                                </div>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    style={{ display: "none" }}>
                                </button>
                                <h4 className="mt-4">
                                    Are you sure want to delete
                                    <br /> this thank you message and replies related to this?
                                </h4>
                                <div>
                                    Reason:
                                    <select
                                        name="deleteReason"
                                        className="form-select cstm-category mr-1"
                                        value={deleteReason}
                                        onChange={handleDeleteInputChange}
                                    >
                                        {Object.entries(ThankyouDeleteReason).map(([key, reason]) => (
                                            <option key={key} value={key}>
                                                {reason}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="mt-4">
                                    <button
                                        className="mr-3 cstm-btn cstn-discard"
                                        onClick={handleDeleteThankyou}
                                    >
                                        Delete
                                    </button>
                                    <button
                                        className="cstm-btn2 cstn-discard"
                                        onClick={onClickCancelDeletePopup}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                show={showFilters}
                onClose={() => setShowFilters(false)}
            >
                <ThankYouListFilters
                    filters={filters}
                    onApplyFilters={onApplyFilters}
                />
            </Modal>
        </SidebarLayoutContent>
    );
}

const singleFilters = ['organizationId', 'platformFlow', 'dateFilterType', 'dateRangeStart', 'dateRangeEnd'];
const multiFilters = ['teamIds'];

function createThankYouListSearchParams(filters, searchText, pageNumber) {
    return createSearchParamsFromListState(filters, searchText, pageNumber, singleFilters, multiFilters);
}

function getThankYouListStateFromSearchParams(searchParams) {
    return getListStateFromSearchParams(searchParams, singleFilters, multiFilters);
}
