import baseApi from "./base-api";

function addNotification(notificationData) {
    return baseApi.post(`admin/nonUser/thankyou/addNotification`, notificationData);
}

function getTeamNotificationsByOrganizationIdAndType(organizationId, notificationType) {
    return baseApi.get(`admin/nonUser/thankyou/teamNotification/${organizationId}/${notificationType}`);
}

function deleteTeamNotification(id) {
    return baseApi.delete(`admin/nonUser/thankyou/teamNotification/${id}`);
}

function getTeamNonUserThankyous(isModeration = false, data) {
    return baseApi.post(`admin/nonUser/thankyou/moderation/${isModeration}`, data);
}

function getDeletedNonUserThankyous(data) {
    return baseApi.post(`admin/nonUser/deleted-thankyous`, data);
}

function getNoContactThankyous(data) {
    return baseApi.post(`admin/nonUser/thankyou/noContact`, data);
}

function releaseNoContactThankyou(id, data) {
    return baseApi.post(`admin/nonUser/thankyou/release/noContact/${id}`, data);
}

function updateModerationFailedThankyouById(moderationFailedThankyouId, data) {
    return baseApi.put(`admin/nonUser/thankyou/${moderationFailedThankyouId}`, data);
}

function updateNonUserThankyou(thankyouId, data) {
    return baseApi.put(`admin/nonUser/thankyou/${thankyouId}`, data);
}

function deleteNonUserThankyou(id, deleteReason) {
    return baseApi.delete(`admin/nonUser/thankyou/${id}/reason/${deleteReason}`);
}

function releaseModerationThankyou(data) {
    return baseApi.post(`admin/nonUser/thankyou/moderation/release`, data);
}

function getUnregisteredThankyous(data) {
    return baseApi.getWithParams(`v2/webadmin/unregistered-organisation-thankyous/unregistered-organisation/${data.organisationId}`, data);
}

const nonUserService = {
    addNotification: addNotification,
    getTeamNotificationsByOrganizationIdAndType: getTeamNotificationsByOrganizationIdAndType,
    deleteTeamNotification: deleteTeamNotification,
    getTeamNonUserThankyous: getTeamNonUserThankyous,
    getNoContactThankyous: getNoContactThankyous,
    releaseNoContactThankyou: releaseNoContactThankyou,
    deleteNonUserThankyou: deleteNonUserThankyou,
    releaseModerationThankyou: releaseModerationThankyou,
    updateModerationFailedThankyouById: updateModerationFailedThankyouById,
    getUnregisteredThankyous: getUnregisteredThankyous,
    updateNonUserThankyou: updateNonUserThankyou,
    getDeletedNonUserThankyous: getDeletedNonUserThankyous
};

export default nonUserService;