import React, { useState, useEffect } from 'react';
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { useForm } from 'react-hook-form';
import DatePicker from "react-datepicker";
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { SidebarLayoutContent } from '../../../../shared/components/SidebarLayoutContent';
import Services from "../../../../Services/organization.service";
import PlacesAutocomplete from 'react-places-autocomplete';
import loader from "../../../../assets/Images/loder.gif";
import { categoryType } from "../../../../Constants/app.constants";
import { ALLOWED_IMAGE_MIME_TYPES } from '../../../../common/constants/file.constant';
import { FormFieldError } from '../../../../shared/components/FormFieldError';
import { config } from '../../../../common/config';
import { APP_ROUTES } from '../../../../common/app-routes';
import { createBreadcrumb, useSetBreadcrumbs } from '../../../../shared/hooks/use-set-breadcrumbs.hook';

const editOrganizationSchema = yup.object().shape({
    name: yup.string().required("Organization name is required"),
    email: yup.string().email("Enter a valid email address").required("Email is required"),
    address: yup.string().required("Organization name is required"),
});

export function EditUnregisteredOrganization() {
    const { register, watch, handleSubmit, reset, formState: { errors, isSubmitting }, setValue } = useForm({
        mode: 'onChange',
        resolver: yupResolver(editOrganizationSchema),
        defaultValues: {
        },
    });
    const [searchParams] = useSearchParams();
    const routeLocation = useLocation();
    const organizationId = searchParams.get('id');
    const [organizationImg, setOrganizationImg] = useState();
    const [organizationImgPreview, setOrganizationImgPreview] = useState();
    const [organizationImgErr, setOrganizationImgErr] = useState("");
    const [bannerImg, setBannerImg] = useState();
    const [bannerImgPreview, setBannerImgPreview] = useState();
    const [bannerImgErr, setBannerImgErr] = useState("");
    const [location, setLocation] = useState("");
    const [locationErr, setLocationErr] = useState("");
    const [googlePlaceId, setGooglePlaceId] = useState("");
    const [organizationDetails, setOrganizationDetails] = useState();

    useEffect(() => {
        getOrganizationDetails();
    }, []);

    useSetBreadcrumbs([
        createBreadcrumb(APP_ROUTES.ORGANIZATION_LIST, routeLocation),
    ]);


    async function getOrganizationDetails() {
        try {
            const response = await Services.getUnregisteredOrganisationById(organizationId);

            if (response?.status === 200) {
                const organizationData = response.data;
                setOrganizationDetails(organizationData);
                reset({
                    name: organizationData.name,
                    email: organizationData.email,
                    address: organizationData.address,
                });
                setGooglePlaceId(organizationData.googlePlaceId);
                setLocation(organizationData.location);
                return;
            }

            toast.error("Couldn't get organization");
        } catch (err) {
            console.log(err);
            toast.error("Couldn't get organization");
        }
    }

    function handlePlaceChange(address) {
        setLocation(address);

        if (!address) {
            setLocationErr("Select your organization on google");
        } else {
            setLocationErr("");
        }
    }

    function handlePlaceSelect(address, placeId) {
        setLocation(address);
        setGooglePlaceId(placeId);

        if (!address) {
            setLocationErr("Select your organization on google");
        } else {
            setLocationErr("");
        }
    }

    async function onSubmit(data) {
    }

    return (
        <SidebarLayoutContent headerTitle='View Unregistered Organisation'>
            <div className="row">
                <h4 className="mb-4">Edit Unregistered Organization</h4>
                <div className="d-flex mb-2">
                    <Link
                        to={`/unregistered-organisation-thankyous/${organizationId}`}
                        className='cstm-btn lh-base ml-2'
                        state={{ breadcrumbPath: `/unregistered-organisation-thankyous/?id=${organizationId}` }}
                    >
                        View Messages
                    </Link>
                </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card border-0 shadow rounded mb-4 p-4">
                    <div className="mb-4 col-md-7">
                        <PlacesAutocomplete
                            value={location}
                            onChange={handlePlaceChange}
                            onSelect={handlePlaceSelect}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                    <i className="fi fi-rr-search cstm-search-ro"></i>
                                    <input
                                        {...getInputProps({
                                            placeholder: 'Search Places ...',
                                            className: 'cstm-input-seacrh location-search-input',
                                        })}
                                    />
                                    <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}

                                        {suggestions.map((suggestion) => {
                                            const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                                            const style = suggestion.active ? { backgroundColor: '#fafafa', cursor: 'pointer' } : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                            return (
                                                <div
                                                    key={suggestion.placeId}
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className,
                                                        style,
                                                    })}
                                                >
                                                    <span>{suggestion.description}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                        <FormFieldError message={locationErr} />
                    </div>

                    <div className="mb-4">
                        <label className="cstm-label">Place Id</label><br />
                        <input
                            type="text"
                            className="cstm-input"
                            placeholder="Google place id"
                            value={googlePlaceId}
                            readOnly
                        />
                    </div>

                    <div className="mb-4 col-md-7">
                        <img src={`${organizationDetails?.image}`} alt='' className="AddOrganization-bannerImg" />
                        {/* <label className="cstm-label">Upload Image</label><br />
                        <div className='cstm-Videoalign'>
                            <label htmlFor="org-image" className='cstm-uploadbtn cstm-btn'>Choose File</label>
                            <input
                                accept="image/*"
                                type="file"
                                name="image"
                                id="org-image"
                                className='cstm-uploadHide'
                                onChange={handleOrganizationImageChange}
                            />
                            {organizationImgPreview && <img src={organizationImgPreview} alt='' className="AddOrganization-bannerImg" />}
                            {!organizationImgPreview && organizationDetails?.image && (
                                <img src={`${config.AWS.S3_BASE_URL}/${organizationDetails?.image}`} alt='' className="AddOrganization-bannerImg" />
                            )}
                        </div>
                        <FormFieldError message={organizationImgErr} /> */}
                    </div>

                    <div className="mb-4 col-md-7">
                        <label className="cstm-label">Organization Name</label>
                        <input
                            type="text"
                            className="cstm-input"
                            placeholder="Organization Display Name"
                            {...register("name")}
                        />
                        <FormFieldError message={errors.name?.message} />
                    </div>

                    <div className="mb-4 col-md-7">
                        <label className="cstm-label">Organisation Email</label>
                        <input
                            type="email"
                            className="cstm-input"
                            placeholder="Organization Email"
                            {...register("email")}
                        />
                        <FormFieldError message={errors.email?.message} />
                    </div>

                    <div className="mb-4 col-md-7">
                        <label className="cstm-label">Address</label>
                        <input
                            type="text"
                            className="cstm-input"
                            placeholder="Address"
                            {...register("address")}
                        />
                        <FormFieldError message={errors.address?.message} />
                    </div>

                    {/* 
                    <div className="submit-btnMain cstm-loader">
                        <button type="submit" className="btn-submit-login-form cstm-submitbutton btn-disable">Edit Organization </button>
                        {isSubmitting && (
                            <div id="login-loader" className='loader-main'>
                                <img src={loader} alt="" />
                            </div>
                        )}
                    </div> */}
                </div>
            </form>
        </SidebarLayoutContent>
    );
}
