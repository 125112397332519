import { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import { useLoaderStore } from '../../../stores/loader.store';
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { getFullImageUrl, downloadQrCode } from "../../../common/utils/utils";
import { Link, useLocation, useSearchParams, useNavigate } from "react-router-dom";
import { SidebarLayoutContent } from '../../../shared/components/SidebarLayoutContent';
import organizationService from "../../../Services/organization.service";
import organizationGroupService from "../../../Services/organization-group.service";
import { ConfirmationModal } from '../../../shared/components/Modal/ConfirmationModal';
import { CopyToClipboard } from "../../../shared/components/CopyToClipboard/CopyToClipboard";

const ORGANIZATION_GROUP_ACTION = Object.freeze({
    DELETE: "DELETE"
});

export function AddEditOrganizationGroup() {
    const history = useNavigate();
    const [searchParams] = useSearchParams();
    const setShowLoader = useLoaderStore((state) => state.setShowLoader);
    const organizationGroupId = searchParams.get('id');
    const [organizationGroupName, setOrganizationGroupName] = useState();
    const [organizationSelectionOptions, setOrganizationSelectionOptions] = useState([]);
    const [selectedOrganizations, setSelectedOrganizations] = useState([]);
    // const [selectedTempOrganizations, setSelectedTempOrganizations] = useState([]);
    const [groupOrganizations, setGroupOrganizations] = useState([]);
    const [qrData, setQrData] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);

    useEffect(() => {
        fetchData();
    }, []);

    async function fetchData() {
        let organizations = await getTOrganizations();

        if (organizationGroupId) {
            let groupOrganizationsData = await getOrganizationGroupById();

            formatOrganizations(organizations, groupOrganizationsData);
        }
    }

    function formatOrganizations(organizations, groupOrganizationsData) {
        let tempOrganizations = [];

        for (let i = 0; i < organizations.length; i++) {
            let found = groupOrganizationsData.find(data => data._id == organizations[i]._id);

            if (!found) {
                tempOrganizations.push(organizations[i])
            }
        }

        const organizationSelectionOptionsData = tempOrganizations.map((organization) => formatOrganizationForDropdown(organization));

        setOrganizationSelectionOptions(organizationSelectionOptionsData);
    }

    async function getOrganizationGroupById() {
        try {
            const res = await organizationGroupService.getOrganizationGroupById(organizationGroupId);

            if (res.status == 200) {
                let organizationGroupData = res.data;

                setOrganizationGroupName(organizationGroupData.groupName);
                setGroupOrganizations(organizationGroupData.organizationIds);
                setQrData(organizationGroupData.qr);
                return organizationGroupData.organizationIds;
            }
        }
        catch (err) {
            toast.error("Couldn't get organisation group data");
        }
    }


    async function onSubmit() {
        if (organizationGroupId) {
            await editOrganizationGroup();
            return;
        }
        await createOrganizationGroup();
    }

    async function getTOrganizations() {
        setOrganizationSelectionOptions([]);

        try {
            const res = await organizationService.getOrganizations(1, 100);

            if (res.status == 200) {
                let organizationsData = res.data?.results;

                if (organizationsData?.length) {
                    const organizationSelectionOptionsData = organizationsData.map((organization) => formatOrganizationForDropdown(organization));

                    setOrganizationSelectionOptions(organizationSelectionOptionsData);
                }

                return organizationsData;
            }

            toast.error("Couldn't get organisations");
            return [];
        } catch (err) {
            toast.error("Couldn't get organisations");
            return [];
        }
    }

    function formatOrganizationForDropdown(organization) {
        return {
            value: organization._id,
            label: organization.name,
        };
    }


    async function createOrganizationGroup() {
        setShowLoader(true);
        try {

            let orgGroupData = {
                groupName: organizationGroupName,
                organizationIds: selectedOrganizations?.map((organization) => organization.value) || [],
            }

            const res = await organizationGroupService.createOrganizationGroup(orgGroupData);

            if (res.status == 200) {
                toast.success("Successfully created organisation group");
                history("/organization-group-list");
                return;
            }

            toast.error("Couldn't create organisation group");
        }
        catch (err) {
            if (err.status == 400 && err.error?.errorCode == "duplicate") {
                showDuplicateTeamMessage(err.error?.data);
                return;
            }
            toast.error("Couldn't create organisation group");
        }
        finally {
            setShowLoader(false);
        }
    }

    function showDuplicateTeamMessage(orgIds) {
        let organizationNames = [];
        for (let i = 0; i < orgIds.length; i++) {
            let organizationData = organizationSelectionOptions.find((data) => data.value == orgIds[i]);

            organizationNames.push(organizationData.label);
        }

        toast.error("Already group assigned to " + organizationNames.toString());
    }

    async function editOrganizationGroup() {
        setShowLoader(true);
        try {

            let orgGroupData = {
                groupName: organizationGroupName,
                organizationIds: selectedOrganizations?.map((organization) => organization.value) || [],
            }

            const res = await organizationGroupService.updateOrganizationGroupById(organizationGroupId, orgGroupData);

            if (res.status == 200) {
                toast.success("Successfully updated organisation group");
                window.location.reload(false);
                return;
            }

            toast.error("Couldn't update organisation group");
        }
        catch (err) {
            if (err.status == 400 && err.error?.errorCode == "duplicate") {
                showDuplicateTeamMessage(err.error?.data);
                return;
            }
            toast.error("Couldn't update organization group");
        }
        finally {
            setShowLoader(false);
        }
    }

    async function handleDeleteOrganization() {
        setShowLoader(true);
        try {

            const deleteOrganization = selectedRow.organization;

            const res = await organizationGroupService.deleteOrganizationFromGroupByGroupById(selectedRow.organizationGroupId, deleteOrganization._id);

            if (res.status == 200) {
                toast.success("Successfully deleted organisation from group");
                await getOrganizationGroupById();
                return;
            }

            toast.error("Couldn't delete organisation from group");
        }
        catch (err) {
            toast.error("Couldn't delete organisation from group");
        }
        finally {
            setSelectedRow(null);
            setShowLoader(false);
        }
    }

    function onDownloadQRClick() {
        downloadQrCode(getFullImageUrl(qrData?.qrCodePath));
    }

    return (
        <SidebarLayoutContent headerTitle={organizationGroupId ? 'Edit Organisation Group' : 'Add Organisation Group'}>
            <div className="row justify-content-between align-items-center p-3">
                <div className="col-md-3">
                    <h4 className="mb-4">{organizationGroupId ? `Edit ${organizationGroupName} Group` : 'Add Organisation Group'}</h4>
                </div>
                <div className="col-md-9">
                    <div className="row row justify-content-end">
                        <div className="col-all-auto">
                            <button onClick={(e) => onSubmit()} type="button" disabled={!organizationGroupName} className="btn-submit-login-form cstm-submitbutton">Save</button>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ minHeight: '1000px' }} className="card border-0 shadow rounded mb-4 p-4">
                <div className="mb-4">
                    <label className="cstm-label">Organisation Group Name: </label>
                    <input
                        className="cstm-input"
                        type="text"
                        placeholder="Enter group name"
                        value={organizationGroupName}
                        onChange={(e) => setOrganizationGroupName(e.target.value)}
                    />
                </div>

                {qrData && qrData.qrCodePath && <div>
                    <label className="cstm-label">Group QR Code</label>
                    <br />
                    <img
                        alt=""
                        style={{ width: 140, height: 140 }}
                        className="mb-2"
                        src={getFullImageUrl(qrData?.qrCodePath)}
                    />
                    <div className="d-flex align-items-center">
                        {qrData?.qrCodePath && (
                            <button
                                variant="primary"
                                className="cstm-btn mr-2"
                                onClick={onDownloadQRClick}
                            >
                                Download
                            </button>
                        )}
                        {qrData?.qrCodeLink && (
                            <CopyToClipboard text={qrData?.qrCodeLink} />
                        )}
                    </div>
                </div>}


                <div>
                    <h4 className="mb-4">Organisations</h4>

                    <div>(Select & Press Save)</div>
                    <div className="row justify-content-between align-items-center">
                        <div className="col-md-3">
                            <label className="cstm-label">Organisation Name</label>
                            <ReactMultiSelectCheckboxes
                                className="form-select"
                                options={organizationSelectionOptions}
                                placeholderButtonLabel="Select Organisation"
                                value={selectedOrganizations}
                                onChange={(value, e) => setSelectedOrganizations(value)}
                                getDropdownButtonLabel={({ placeholderButtonLabel, value }) => `${placeholderButtonLabel}: ${value.length} organisation(s) selected`}
                            />
                        </div>
                        {/* <div className="col-md-9">
                            <div className="">
                                <button type="button" onClick={(e) => setSelectedOrganizations(selectedTempOrganizations)} className="btn-submit-login-form cstm-submitbutton">Add</button>
                            </div>
                        </div> */}
                    </div>

                    <div style={{ marginTop: '20px' }}>
                        <div className="overflow-auto">
                            <table className="table mb-0 table-center">
                                <thead>
                                    <tr className="border-top">
                                        <th className="border-bottom w-4 cstm-userheading">No</th>
                                        <th className="border-bottom w-4 cstm-userheading">Organisation</th>
                                        <th className="border-bottom w-4 cstm-userheading">Actions</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {groupOrganizations?.map((organization, index) => (
                                        <tr
                                            key={organization._id}
                                            className="cstm-Tabledesign cstm-usertable-design"
                                        >
                                            <td>{index + 1}</td>
                                            <td>
                                                <Link
                                                    to={`/view-organization/${organization._id}`}>
                                                    {organization.name}
                                                </Link>
                                            </td>

                                            <td>
                                                <div>
                                                    <button
                                                        onClick={() => setSelectedRow({ organization: organization, organizationGroupId: organizationGroupId, action: ORGANIZATION_GROUP_ACTION.DELETE })}
                                                        class="cstm-delete mrn-rt"
                                                    >
                                                        <i class="fi fi-rr-trash"></i>
                                                    </button>

                                                </div>
                                            </td>
                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>

            {(selectedRow && selectedRow.action === ORGANIZATION_GROUP_ACTION.DELETE) && (
                <ConfirmationModal
                    text='Are you sure you want to delete this organisation?'
                    confirmBtnText='Delete'
                    type="delete"
                    onConfirm={handleDeleteOrganization}
                    onCancel={() => setSelectedRow(null)}
                />
            )}
        </SidebarLayoutContent >
    );
}