import { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { formatDateForTable } from '../../../common/utils/date.util';
import nonUserService from "../../../Services/non-user.service";
import { DEFAULT_PAGE_SIZE } from '../../../common/constants/pagination.constant';
import { SidebarLayoutContent } from '../../../shared/components/SidebarLayoutContent';
import { Pagination } from '../../shared/Pagination';
import { ThankyouDeleteReason } from '../../../common/enums/thankyou-delete-reason';
import { useLoaderStore } from '../../../stores/loader.store';
import { createSearchParamsFromListState, getListStateFromSearchParams } from '../../../utils/list-state.util';

export function DeletedunregisteredUserThankYouList() {
    const location = useLocation();
    const setShowLoader = useLoaderStore((state) => state.setShowLoader);
    const [searchParams, setSearchParams] = useSearchParams();
    const [resultsCount, setResultsCount] = useState(0);
    const state = useMemo(() => getThankYouListStateFromSearchParams(searchParams), [searchParams]);
    const pagination = { pageNumber: state.pageNumber, pageSize: DEFAULT_PAGE_SIZE };
    const [thankYous, setThankYous] = useState([]);
    const [deleteThankyou, setDeleteThankyou] = useState('');
    const [selectedThankyou, setSelectedThankyou] = useState({});
    const [searchText, setSearchText] = useState();

    useEffect(() => {
        getUsernonUserThankyous();
    }, []);

    useEffect(() => {
        getUsernonUserThankyous();
    }, [searchParams]);

    async function getUsernonUserThankyous() {

        try {
            const res = await nonUserService.getDeletedNonUserThankyous({
                page: pagination.pageNumber,
                limit: pagination.pageSize,
            });

            if (res.status === 200) {
                setThankYous(res.data.thankyou_data);
                setResultsCount(res.data.state?.data_count);
                // toast.success("successfully fetched thankyous");
                return;
            }

            toast.error("Couldn't get thankyous");
        } catch (err) {
            if (err.error) {
                toast.error(err.error);
                return;
            }
            toast.error("Couldn't get thankyous");
        } finally {
            setShowLoader(false);
        }
    }

    function onPaginationChange(pageNumber) {
        setSearchParams(createThankYouListSearchParams({}, '', pageNumber));
    }

    return (
        <SidebarLayoutContent headerTitle='Deleted Unregistered User Thank you List'>
            <div className="card border-0 shadow rounded">
                <div className='d-flex justify-content-end align-items-center p-2'>
                    {/* <div className="mr-2">
                        <i className="fi fi-rr-search cstm-search-ro"></i>
                        <input
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            type="text"
                            className="cstm-input-seacrh"
                            placeholder="Search"
                        />
                    </div> */}
                </div>

                <div className="overflow-auto">
                    <table className="table mb-0 table-center">
                        <thead>
                            <tr className="border-top">
                                <th className="border-bottom w-4 cstm-userheading">Message</th>
                                <th className="border-bottom w-4 cstm-userheading">From</th>
                                <th className="border-bottom w-4 cstm-userheading">To</th>
                                <th className="border-bottom w-4 cstm-userheading">Organization</th>
                                <th className="border-bottom w-4 cstm-userheading">Team</th>
                                <th className="border-bottom w-4 cstm-userheading">Reason</th>
                                <th className="border-bottom w-4 cstm-userheading">Created Date</th>
                            </tr>
                        </thead>

                        <tbody>
                            {thankYous?.map((thankyou, index) => (
                                <tr
                                    key={thankyou._id}
                                    className="cstm-Tabledesign cstm-usertable-design"
                                >
                                    <td>{thankyou.message}</td>

                                    <td>
                                        <div>{thankyou.fromUserData?.firstName}</div>
                                        <div>{thankyou.fromUserData?.email ? thankyou.fromUserData?.email : ''}</div>
                                        <div>{thankyou.fromUserData?.phoneNumber ? thankyou.fromUserData?.phoneNumber : ''}</div>
                                    </td>
                                    <td>{thankyou.toStaffName} {thankyou.toStaffLastName}- <br />Unregistered</td>
                                    <td>{thankyou.organization[0]?.name}</td>
                                    <td>{thankyou.team[0]?.name}</td>
                                    <td>{ThankyouDeleteReason[thankyou.deleteReason]}</td>
                                    <td>{formatDateForTable(thankyou.createdAt)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {!thankYous?.length && (
                        <div className="NoRecord-cstm">No records found!</div>
                    )}
                </div>
            </div>

            <Pagination
                totalRows={resultsCount}
                currentPage={pagination.pageNumber}
                rowsPerPage={pagination.pageSize}
                onPaginationChange={onPaginationChange}
            />

        </SidebarLayoutContent>
    );
}

function createThankYouListSearchParams(filters, searchText, pageNumber) {
    return createSearchParamsFromListState(filters, searchText, pageNumber);
}

function getThankYouListStateFromSearchParams(searchParams) {
    return getListStateFromSearchParams(searchParams);
}
