import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SidebarLayoutHeader } from '../../../shared/components/SidebarLayoutHeader';
import { formatDateForTable } from '../../../common/utils/date.util';
import { useLoaderStore } from '../../../stores/loader.store';
import { useSetBreadcrumbs } from '../../../shared/hooks/use-set-breadcrumbs.hook';
import reportedMessagesService from '../../../Services/reported-messages.service';
import thankYouUtils from '../../../utils/thankyou.util';
import giftUtils from '../../../utils/gift.util';
import { ConfirmationModal } from '../../../shared/components/Modal/ConfirmationModal';

/**
 * @typedef ReportedMessage
 * @property {string} _id
 * @property {string} message
 * @property {"thankyou" | "thankyou-reply" | "gift" | "gift-reply"} type
 * @property {{ firstName: string, email: string, phoneNumber: string }} sender
 * @property {{ firstName: string, email: string, phoneNumber: string }} receiver
 * @property {string} createdAt
 */

const REPORTED_MESSAGE_ACTION = Object.freeze({
    DELETE: "DELETE",
});

/**
 * @typedef SelectedRow
 * @property {ReportedMessage} reportedMessage
 * @property {keyof typeof REPORTED_MESSAGE_ACTION} action
 */

export function ReportedMessagesList() {
    const setShowLoader = useLoaderStore((state) => state.setShowLoader);

    /** @type {[ReportedMessage[], React.Dispatch<ReportedMessage[]>]} */
    const [reportedMessages, setReportedMessages] = useState([]);

    /** @type {[SelectedRow, React.Dispatch<SelectedRow>]} */
    const [selectedRow, setSelectedRow] = useState(null);

    useSetBreadcrumbs([{ label: 'Reported Messages', path: '' }]);

    useEffect(() => {
        getReportedMessages();
    }, []);

    async function getReportedMessages() {
        setShowLoader(true);

        try {
            const res = await reportedMessagesService.getReportedMessages();

            if (res.status === 200) {
                setReportedMessages(formatData(res.data));
                return;
            }

            toast.error("Couldn't get reported messages");
        } catch (err) {
            toast.error("Couldn't get reported messages");
        } finally {
            setShowLoader(false);
        }
    }

    function formatData(data) {
        /** @type {Array<ReportedMessage>} */
        const formattedReportedMessages = [];

        data.reportedThankyousAndRepliesData?.results?.forEach((thankYou) => {
            const senderDetails = thankYouUtils.getThankYouSenderDetails(thankYou);
            const receiver = thankYou.users[0];
            formattedReportedMessages.push({
                _id: thankYou._id,
                message: thankYou.description,
                type: thankYou.reply_flag ? "thankyou-reply" : "thankyou",
                sender: {
                    firstName: senderDetails?.firstName,
                    email: senderDetails?.email,
                    phoneNumber: senderDetails?.phoneNumber,
                },
                receiver: {
                    firstName: receiver?.firstName,
                    email: receiver?.email,
                    phoneNumber: receiver?.phoneNumber,
                },
                createdAt: thankYou.createdAt,
            });
        });
        data.reportedGiftsAndRepliesData?.results?.forEach((gift) => {
            const senderDetails = giftUtils.getGiftSenderDetails(gift);
            const receiver = gift.users[0];
            formattedReportedMessages.push({
                _id: gift._id,
                message: gift.message,
                type: gift.reply_flag ? "gift-reply" : "gift",
                sender: {
                    firstName: senderDetails?.firstName,
                    email: senderDetails?.email,
                    phoneNumber: senderDetails?.phoneNumber,
                },
                receiver: {
                    firstName: receiver?.firstName,
                    email: receiver?.email,
                    phoneNumber: receiver?.phoneNumber,
                },
                createdAt: gift.createdAt,
            });
        });

        return formattedReportedMessages;
    }

    /**
     * @param {ReportedMessage["type"]} type 
     */
    function getReportedMessageTypeLabel(type) {
        if (type === "thankyou") {
            return "Thank You";
        }
        if (type === "thankyou-reply") {
            return "Thank You Reply";
        }
        if (type === "gift") {
            return "Gift";
        }
        if (type === "gift-reply") {
            return "Gift Reply";
        }
    }

    async function handleDeleteReportedMessage() {
        try {
            const reportedMessage = selectedRow.reportedMessage;
            const res = await reportedMessagesService.deleteReportedMessage(reportedMessage._id, reportedMessage.type);

            if (res.status === 200) {
                toast.success("Successfully deleted reported message");
                setReportedMessages((current) => current.filter((data) => data._id !== reportedMessage._id));
                return;
            }

            toast.error("Couldn't delete reported message");
        } catch (err) {
            console.log(err);
            toast.error("Couldn't delete reported message");
        } finally {
            setSelectedRow(null);
        }
    }

    return (
        <>
            <main className="page-content bg-light">
                <SidebarLayoutHeader title='Reported Messages List' />

                <div className="container-fluid">
                    <div className="layout-specing">
                        <div className="card border-0 shadow rounded">
                            <div className="overflow-auto">
                                <table className="table mb-0 table-center">
                                    <thead>
                                        <tr className="border-top">
                                            <th className="border-bottom w-4 cstm-userheading">Message</th>
                                            <th className="border-bottom w-4 cstm-userheading">From</th>
                                            <th className="border-bottom w-4 cstm-userheading">To</th>
                                            <th className="border-bottom w-4 cstm-userheading">Team</th>
                                            <th className="border-bottom w-4 cstm-userheading">Type</th>
                                            <th className="border-bottom w-4 cstm-userheading">Created Date</th>
                                            <th className="border-bottom w-4 cstm-userheading">Actions</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {reportedMessages?.map((reportedMessage) => (
                                            <tr
                                                key={reportedMessage._id}
                                                className="cstm-Tabledesign cstm-usertable-design"
                                            >
                                                <td>{reportedMessage.message}</td>
                                                <td>
                                                    <div>{reportedMessage?.sender?.firstName}</div>
                                                    <div>{reportedMessage?.sender?.email}</div>
                                                    <div>{reportedMessage?.sender?.phoneNumber}</div>
                                                </td>
                                                <td>
                                                    <div>{reportedMessage?.receiver?.firstName}</div>
                                                    <div>{reportedMessage?.receiver?.email}</div>
                                                    <div>{reportedMessage?.receiver?.phoneNumber}</div>
                                                </td>
                                                <td>
                                                    <div>{reportedMessage.teams && reportedMessage.teams[0] ? reportedMessage.teams[0]?.name : 'N/A'}</div>
                                                </td>
                                                <td>{getReportedMessageTypeLabel(reportedMessage.type)}</td>
                                                <td>{reportedMessage.createdAt ? formatDateForTable(reportedMessage.createdAt) : null}</td>
                                                <td>
                                                    <div>
                                                        <Link
                                                            to={""}
                                                            onClick={() => setSelectedRow({ reportedMessage: reportedMessage, action: REPORTED_MESSAGE_ACTION.DELETE })}
                                                            className="cstm-delete mrn-rt"
                                                        >
                                                            <i className="fi fi-rr-trash"></i>
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                                {!reportedMessages?.length && (
                                    <div className="NoRecord-cstm">No records found!</div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            {(selectedRow && selectedRow.action === REPORTED_MESSAGE_ACTION.DELETE) && (
                <ConfirmationModal
                    text='Are you sure you want to delete this reported message?'
                    confirmBtnText='Delete'
                    type='delete'
                    onConfirm={handleDeleteReportedMessage}
                    onCancel={() => setSelectedRow(null)}
                />
            )}
        </>
    );
}
