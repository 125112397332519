import { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useSearchParams, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { formatDateForTable } from '../../common/utils/date.util';
import nonUserService from "../../Services/non-user.service";
import { DEFAULT_PAGE_SIZE } from '../../common/constants/pagination.constant';
import { SidebarLayoutContent } from '../../shared/components/SidebarLayoutContent';
import { Pagination } from '../shared/Pagination';
import { useLoaderStore } from '../../stores/loader.store';
import { createSearchParamsFromListState, getListStateFromSearchParams } from '../../utils/list-state.util';

export function UnregisteredOrganisationThankYouList() {
    const location = useLocation();
     const { organisationId } = useParams();
    const setShowLoader = useLoaderStore((state) => state.setShowLoader);
    const [searchParams, setSearchParams] = useSearchParams();
    const [resultsCount, setResultsCount] = useState(0);
    const state = useMemo(() => getThankYouListStateFromSearchParams(searchParams), [searchParams]);
    const pagination = { pageNumber: state.pageNumber, pageSize: DEFAULT_PAGE_SIZE };
    const [thankYous, setThankYous] = useState([]);

    useEffect(() => {
        getUnregisteredThankyous();
    }, []);

    useEffect(() => {
        getUnregisteredThankyous();
    }, [searchParams]);


    async function getUnregisteredThankyous() {

        try {
            const res = await nonUserService.getUnregisteredThankyous({
                organisationId: organisationId,
                page: pagination.pageNumber,
                limit: pagination.pageSize,
            });

            if (res.status === 200) {
                setThankYous(res.data.results);
                setResultsCount(res.data.count);
                // toast.success("successfully fetched thankyous");
                return;
            }

            toast.error("Couldn't get thankyous");
        } catch (err) {
            if (err.error) {
                toast.error(err.error);
                return;
            }
            toast.error("Couldn't get thankyous");
        } finally {
            setShowLoader(false);
        }
    }

    function onPaginationChange(pageNumber) {
        setSearchParams(createThankYouListSearchParams({}, '', pageNumber));
    }


    return (
        <SidebarLayoutContent showHeaderBreadcrumbs={false} headerTitle='Unregisterd Organisation Thank you List'>
            <div className="card border-0 shadow rounded">

                <div className="overflow-auto">
                    <table className="table mb-0 table-center">
                        <thead>
                            <tr className="border-top">
                                <th className="border-bottom w-4 cstm-userheading">Message</th>
                                <th className="border-bottom w-4 cstm-userheading">From</th>
                                <th className="border-bottom w-4 cstm-userheading">To</th>
                                <th className="border-bottom w-4 cstm-userheading">Organization</th>
                                <th className="border-bottom w-4 cstm-userheading">Created Date</th>
                            </tr>
                        </thead>

                        <tbody>
                            {thankYous?.map((thankyou, index) => (
                                <tr
                                    key={thankyou._id}
                                    className="cstm-Tabledesign cstm-usertable-design"
                                >
                                    <td>{thankyou.message}</td>

                                    <td>
                                        <div>{thankyou.fromUserData?.firstName}</div>
                                        <div>{thankyou.fromUserData?.email ? thankyou.fromUserData?.email : ''}</div>
                                        <div>{thankyou.fromUserData?.phoneNumber ? thankyou.fromUserData?.phoneNumber : ''}</div>
                                    </td>
                                    <td>{thankyou.toStaffName} {thankyou.toStaffLastName}- <br />Unregistered</td>
                                    <td>{thankyou.unregisteredOrganisationId?.name}</td>
                                    <td>{formatDateForTable(thankyou.createdAt)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {!thankYous?.length && (
                        <div className="NoRecord-cstm">No records found!</div>
                    )}
                </div>
            </div>

            <Pagination
                totalRows={resultsCount}
                currentPage={pagination.pageNumber}
                rowsPerPage={pagination.pageSize}
                onPaginationChange={onPaginationChange}
            />
        </SidebarLayoutContent>
    );
}

function createThankYouListSearchParams(filters, searchText, pageNumber) {
    return createSearchParamsFromListState(filters, searchText, pageNumber);
}

function getThankYouListStateFromSearchParams(searchParams) {
    return getListStateFromSearchParams(searchParams);
}
