import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Modal } from '../../../shared/components/Modal/Modal';
import { EditFailedGift } from "./EditFailedGift";
import { SidebarLayoutHeader } from '../../../shared/components/SidebarLayoutHeader';
import { Pagination } from '../../shared/Pagination';
import { formatDateForTable } from '../../../common/utils/date.util';
import { useLoaderStore } from '../../../stores/loader.store';
import { useSetBreadcrumbs } from '../../../shared/hooks/use-set-breadcrumbs.hook';
import { ReleaseModerationFailedGift } from "./ReleaseModerationFailedGift";
import giftService from '../../../Services/gift.service';
import { highlightThankyouMessageMatchInRed } from '../../../common/utils/text-highlight';

const MODERATION_FAILED_MESSAGE_ACTION = Object.freeze({
    DELETE: "DELETE",
    EDIT: "EDIT",
    RELEASE: "RELEASE"
});

/**
 * @typedef SelectedRow
 * @property {string} moderationFailedMessage
 * @property {keyof typeof MODERATION_FAILED_MESSAGE_ACTION} action
 */

export function ModerationFailedGiftList() {
    const setShowLoader = useLoaderStore((state) => state.setShowLoader);
    const [draftGifts, setDraftGifts] = useState([]);
    const [pagination, setPagination] = useState({ pageNumber: 1, pageSize: 20 });
    const [resultsCount, setResultsCount] = useState(0);
    const [filterData, setFilterData] = useState('notReleased');
    const [isFirstRender, setIsFirstRender] = useState(true);

    /** @type {[SelectedRow, React.Dispatch<SelectedRow>]} */
    const [selectedRow, setSelectedRow] = useState(null);

    useSetBreadcrumbs([{ label: 'Moderation Failed Messages', path: '' }]);

    useEffect(() => {
        let pageNumber = 1;
        setPagination({
            pageNumber: pageNumber,
            pageSize: pagination.pageSize,
        });

        getGifts(pageNumber, pagination.pageSize);
        setIsFirstRender(false);
    }, []);

    useEffect(() => {
        if (!isFirstRender) {
            let pageNumber = 1;
            setPagination({
                pageNumber: pageNumber,
                pageSize: pagination.pageSize,
            });

            getGifts(pageNumber, pagination.pageSize);
        }
    }, [filterData]);


    async function getGifts(pageNumber, pageSize) {
        setShowLoader(true);

        try {
            const res = await giftService.getModerationFailedGifts({
                pageNumber: pageNumber,
                pageSize: pageSize,
                status: filterData
            });

            if (res.status === 200) {
                let messages = res.data.results;

                for (let i = 0; i < messages.length; i++) {
                    let item = messages[i];
                    if (item.failedReason) {
                        let highlightData = highlightThankyouMessageMatchInRed(item.message, item.createdUserData, item.failedReason);
                        item.message = highlightData.message;
                        item.createdUserData = highlightData.contact;
                    }
                }

                setDraftGifts(messages);
                setResultsCount(res.data.count);

                return;
            }

            toast.error("Couldn't get thank yous");
        } catch (err) {
            toast.error("Couldn't get thank yous");
        } finally {
            setShowLoader(false);
        }
    }

    function onPaginationChange(pageNumber) {
        setPagination({
            pageNumber: pageNumber,
            pageSize: pagination.pageSize,
        });

        getGifts(pageNumber, pagination.pageSize);
    }



    return (
        <>
            <main className="page-content bg-light">
                <SidebarLayoutHeader title="Failed Gift List" />

                <div className="container-fluid">
                    <div className="layout-specing">
                        <div className="card border-0 shadow rounded">
                            <div className='d-flex justify-content-end align-items-center p-2'>
                                <div className="">
                                    <button
                                        className='cstm-btn lh-base ml-2'
                                        onClick={(e) => setFilterData('released')}
                                    >
                                        Released Gifts
                                    </button>
                                </div>
                            </div>

                            <div className="col-md-12 col-lg-12">
                                <table className="table mb-0 table-center">
                                    <thead>
                                        <tr className="border-top">
                                            <th className="border-bottom w-4 cstm-userheading">Message</th>
                                            <th className="border-bottom w-4 cstm-userheading">From</th>
                                            <th className="border-bottom w-4 cstm-userheading">To</th>
                                            <th className="border-bottom w-4 cstm-userheading">Team</th>
                                            <th className="border-bottom w-4 cstm-userheading">Created Date</th>
                                            <th className="border-bottom w-4 cstm-userheading">Actions</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {draftGifts?.map((gift) => {
                                            return (
                                                <tr
                                                    key={gift._id}
                                                    className="cstm-Tabledesign cstm-usertable-design"
                                                >
                                                    <td><p style={{ color: "black" }} dangerouslySetInnerHTML={{ __html: gift.message }} /></td>
                                                    <td>
                                                        <div>{gift.createdUserData?.firstName}</div>
                                                        <div><p style={{ color: "black" }} dangerouslySetInnerHTML={{ __html: gift.createdUserData?.email }} /></div>
                                                        <div>{gift.createdUserData?.phoneNumber}</div>
                                                    </td>
                                                    <td>
                                                        <div>{gift.users ? gift.users[0]?.firstName : null}</div>
                                                        <div>{gift.users ? gift.users[0]?.email : null}</div>
                                                        <div>{gift.users ? gift.users[0]?.phoneNumber : null}</div>
                                                    </td>
                                                    <td>
                                                        <div>{gift.teams && gift.teams[0] ? gift.teams[0]?.name : 'N/A'}</div>
                                                    </td>

                                                    <td>{gift.createdAt ? formatDateForTable(gift.createdAt) : null}</td>
                                                    <td>
                                                        <div>
                                                            <button
                                                                to={""}
                                                                onClick={() => setSelectedRow({ moderationFailedMessage: gift, action: MODERATION_FAILED_MESSAGE_ACTION.EDIT })}
                                                                class="cstm-delete mrn-rt"
                                                            >
                                                                <i className="fi fi-rr-pencil"></i>
                                                            </button>
                                                            {gift.isReleased ? 'Released' :
                                                                <button
                                                                    type='button'
                                                                    className="cstm-eye"
                                                                    onClick={() => setSelectedRow({ moderationFailedMessage: gift, action: MODERATION_FAILED_MESSAGE_ACTION.RELEASE })}
                                                                    class="cstm-delete mrn-rt"
                                                                >
                                                                    <i className="fi fi-rr-cloud-upload"></i>

                                                                </button>}
                                                        </div>
                                                    </td>

                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>

                                {!draftGifts?.length && (
                                    <div className="NoRecord-cstm">No records found!</div>
                                )}
                            </div>
                        </div>

                        <Pagination
                            totalRows={resultsCount}
                            currentPage={pagination.pageNumber}
                            rowsPerPage={pagination.pageSize}
                            onPaginationChange={onPaginationChange}
                        />
                    </div>
                </div>
            </main>

            {(selectedRow && selectedRow.action === MODERATION_FAILED_MESSAGE_ACTION.EDIT) && (
                <Modal
                    show={true}
                    onClose={() => setSelectedRow(null)}
                >
                    <EditFailedGift
                        moderationFailedGift={selectedRow.moderationFailedMessage}
                        onEditSuccess={() => {
                            setSelectedRow(null);
                            getGifts(pagination.pageNumber, pagination.pageSize);
                        }}
                    />
                </Modal>
            )}

            {(selectedRow && selectedRow.action === MODERATION_FAILED_MESSAGE_ACTION.RELEASE) && (
                <Modal
                    show={true}
                    onClose={() => setSelectedRow(null)}
                >
                    <ReleaseModerationFailedGift
                        moderationFailedGift={selectedRow.moderationFailedMessage}
                        onReleaseSuccess={() => {
                            setSelectedRow(null);
                        }}
                    />
                </Modal>
            )}
        </>
    );
}