import { useState } from 'react';
import { toast } from 'react-toastify';
import { useLoaderStore } from '../../../stores/loader.store';
import nonUserService from "../../../Services/non-user.service";

export function EditNonUserThankyou({ nonUserThankyou, onEditSuccess }) {
    const setShowLoader = useLoaderStore((state) => state.setShowLoader);
    const [email, setEmail] = useState(nonUserThankyou.fromUserData?.email || "");

    async function editNonUserThankyou() {
        setShowLoader(true);

        try {
            let fromUserData = nonUserThankyou.fromUserData;
            fromUserData.email = email;

            let nonUserThankyouUpdateData = {
                fromUserData
            }

            const res = await nonUserService.updateNonUserThankyou(nonUserThankyou._id, nonUserThankyouUpdateData);

            if (res.status === 200) {
                toast.success("Successfully updated unregistered user thank you");
                onEditSuccess();
                return;
            }

            toast.error("Couldn't update unregistered user thank you");
        } catch (err) {
            console.log(err);
            toast.error("Couldn't update unregistered user thank you");
        } finally {
            setShowLoader(false);
        }
    }

    return (
        <div style={{ maxWidth: '700px', maxHeight: '700px', overflowY: "auto" }} className='p-2'>
            <div className="mb-2">
                <div className='w-50'>
                    <span className="cstm-label">From</span>
                    <p>{nonUserThankyou.fromUserData?.firstName}</p>
                </div>
                {nonUserThankyou.fromUserData?.email && <div className='mb-2'>
                    <span className="cstm-label">From Email</span>
                    <input
                        type="text"
                        className="cstm-input"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>}

                <div className='w-50'>
                    <span className="cstm-label">To</span>
                    <p>{nonUserThankyou.toStaffName} {nonUserThankyou.toStaffLastName}</p>
                </div>
            </div>

            <div className="d-flex justify-content-end">
                <button type='button' className='cstm-submitbutton' onClick={editNonUserThankyou}>Save</button>
            </div>
        </div>
    );
}
