import baseApi from "./base-api";

function getOrganizations({ search, pageNumber, pageSize } = {}) {
    return baseApi.getWithParams("organizations", {
        search: search,
        page: pageNumber,
        limit: pageSize,
    });
}

/**
 * @param {Object} params
 * @param {string} params.includeTeamCount
 * @param {string} params.includeUserCount
 */
function getOrganizationById(organizationId, params = {}) {
    return baseApi.getWithParams(`organizations/${organizationId}`, params);
}

function getUnregisteredOrganisations(params = {}) {
    return baseApi.get(`v2/webadmin/unregistered-organisations`);
}

function getUnregisteredOrganisationById(id) {
    return baseApi.get(`v2/webadmin/unregistered-organisations/${id}`);
}

function addTeamToOrganization(team) {
    return baseApi.post(`admin/add-team-to-organization`, team);
}

function generateEmailBannerByOrganisationId(organisationId, params = {}) {
    return baseApi.put(`v2/webadmin/organizations/${organisationId}/email-banners`, params);
}

const organizationService = {
    getOrganizations: getOrganizations,
    getOrganizationById: getOrganizationById,
    addTeamToOrganization: addTeamToOrganization,
    generateEmailBannerByOrganisationId: generateEmailBannerByOrganisationId,
    getUnregisteredOrganisations: getUnregisteredOrganisations,
    getUnregisteredOrganisationById: getUnregisteredOrganisationById
};

export default organizationService;
