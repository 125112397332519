import { useQuery } from '@tanstack/react-query';
import totalRevenueImg from "../../assets/Images/total-revenue.png";
import joinedCharityImg from "../../assets/Images/joined-charity.png";
import joinedOrganizationImg from "../../assets/Images/joined-organization.png";
import joniedUsersImg from "../../assets/Images/jonied-users.png";
import { HelperInfoTooltip } from "../../shared/components/Tooltip/HelperInfoTooltip";
import { QUERY_KEYS } from '../../common/query-keys';
import statsService from '../../Services/stats.service';
import { HelperInfoCode } from '../../common/models/helper-info-code.enum';
import { useGetHelperInfoByCode } from '../../hooks/webadmin-content/webadmin-content.hook';

/**
 * @param {Object} props
 */
export function GlobalStats() {
    const { data: globalStatsRes, isLoading } = useQuery({
        queryKey: [QUERY_KEYS.GLOBAL_STATS],
        queryFn: () => statsService.getGlobalStats(),
        staleTime: Infinity,
    });
    const globalStats = globalStatsRes?.data;

    // the revenue is currently not tracked in application
    // therefore this is done to take a static value which is editable using helper info
    const { data: revenueInfo } = useGetHelperInfoByCode(HelperInfoCode.DASHBOARD_GLOBAL_STATS_REVENUE);
    const revenueValue = revenueInfo?.description || "$0";

    return (
        <div className="row mb-4">
            {/* <div className="col-xl-3 col-lg-6 col-md-3">
                <GlobalStatsCard
                    title="Revenue"
                    content={`${revenueValue}`}
                    imgSrc={totalRevenueImg}
                    helperInfoCode={HelperInfoCode.DASHBOARD_GLOBAL_STATS_REVENUE}
                    isLoading={isLoading}
                />
            </div> */}
            <div className="col-xl-3 col-lg-6 col-md-3">
                <GlobalStatsCard
                    title="Users"
                    content={globalStats?.userCount || 0}
                    imgSrc={joniedUsersImg}
                    helperInfoCode={HelperInfoCode.DASHBOARD_GLOBAL_STATS_USER_COUNT}
                    isLoading={isLoading}
                />
            </div>
            <div className="col-xl-3 col-lg-6 col-md-3">
                <GlobalStatsCard
                    title="Hospitals/Clinics"
                    content={globalStats?.organizationCount || 0}
                    imgSrc={joinedOrganizationImg}
                    helperInfoCode={HelperInfoCode.DASHBOARD_GLOBAL_STATS_ORGANIZATION_COUNT}
                    isLoading={isLoading}
                />
            </div>
            <div className="col-xl-3 col-lg-6 col-md-3">
                <GlobalStatsCard
                    title="Charities"
                    content={globalStats?.charityCount || 0}
                    imgSrc={joinedCharityImg}
                    helperInfoCode={HelperInfoCode.DASHBOARD_GLOBAL_STATS_CHARITY_COUNT}
                    isLoading={isLoading}
                />
            </div>
        </div>
    );
}

/**
 * @param {Object} props
 * @param {string} props.title
 * @param {React.ReactNode} props.content
 * @param {any} props.imgSrc
 * @param {string} props.helperInfoCode
 * @param {boolean} props.isLoading
 */
function GlobalStatsCard({ title, content, imgSrc, helperInfoCode, isLoading }) {
    return (
        <div className={`card position-relative features feature-primary rounded border-0 shadow p-3 ${isLoading ? 'custom-skeleton-loader-full' : ''}`}>
            <div className="position-absolute top-0 end-0 p-2">
                <HelperInfoTooltip code={helperInfoCode} />
            </div>

            <div className="d-flex align-items-center">
                <div>
                    <img src={imgSrc} alt='' />
                </div>
                <div className="flex-1 ms-3">
                    <p className="text-muted mb-0 cstm-tx14">{title}</p>
                    <h4 className="mb-0">{content}</h4>
                </div>
            </div>
        </div>
    );
}
