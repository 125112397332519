import { useState } from 'react';
import { toast } from 'react-toastify';
import { useLoaderStore } from '../../../stores/loader.store';
import nonUserService from '../../../Services/non-user.service';

export function EditModerationFailedNonUserThankYou({ moderationFailedThankyou, onEditSuccess }) {
    const setShowLoader = useLoaderStore((state) => state.setShowLoader);
    const [email, setEmail] = useState(moderationFailedThankyou?.fromUserData?.email || "");
    const [investigationNotes, setInvestigationNotes] = useState(moderationFailedThankyou?.investigationNotes || "");
    const [actionsTaken, setActionsTaken] = useState(moderationFailedThankyou?.actionsTaken || "");
    const [analysedByName, setAnalysedByName] = useState(moderationFailedThankyou?.analysedByName || "");

    async function editModerationFailedThankyou() {
        setShowLoader(true);

        try {
            let fromUserData = moderationFailedThankyou.fromUserData;
            fromUserData.email = email;
            
            let updateData = {
                investigationNotes: investigationNotes,
                actionsTaken: actionsTaken,
                analysedByName: analysedByName,
                fromUserData
            };

         

            const res = await nonUserService.updateModerationFailedThankyouById(moderationFailedThankyou._id, updateData);

            if (res.status === 200) {
                toast.success("Successfully updated moderation failed thankyou");
                onEditSuccess();
                return;
            }

            toast.error("Couldn't update moderation failed thankyou");
        } catch (err) {
            console.log(err);
            toast.error("Couldn't update moderation failed thankyou");
        } finally {
            setShowLoader(false);
        }
    }

    return (
        <div style={{ maxWidth: '600px', maxHeight: '650px', overflowY: "auto" }} className='p-2'>
            <div className="mb-2">
                <div className="mb-2">
                    <span className="cstm-label">Message</span>
                    <p>{moderationFailedThankyou?.message}</p>
                </div>

            </div>

            <div>
                <div className="mb-2">
                    <div className='w-50'>
                        <span className="cstm-label">From</span>
                        <p>{moderationFailedThankyou.fromUserData?.firstName}</p>
                    </div>

                    {moderationFailedThankyou.fromUserData?.email && <div className='mb-2'>
                        <span className="cstm-label">From Email</span>
                        <input
                            type="text"
                            className="cstm-input"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>}
                </div>
            </div>

            <div className="mb-2">
                <div className='w-50'>
                    <span className="cstm-label">To</span>
                    <p>{moderationFailedThankyou.toStaffName ? moderationFailedThankyou.toStaffName : null}</p>
                </div>
            </div>

            <div className="mb-2">
                <label className="cstm-label">Investigation Notes</label>
                <textarea
                    className='cstm-textarea'
                    rows={4}
                    cols={40}
                    value={investigationNotes}
                    onChange={(e) => setInvestigationNotes(e.target.value)}
                ></textarea>
            </div>

            <div className="mb-2">
                <label className="cstm-label">Actions Taken</label>
                <textarea
                    className='cstm-textarea'
                    rows={4}
                    cols={40}
                    value={actionsTaken}
                    onChange={(e) => setActionsTaken(e.target.value)}
                ></textarea>
            </div>

            <div className="mb-2">
                <label className="cstm-label">Analysed By</label>
                <input
                    type="text"
                    className="cstm-input"
                    value={analysedByName}
                    onChange={(e) => setAnalysedByName(e.target.value)}
                />
            </div>

            <div className="d-flex justify-content-end">
                <button type='button' className='cstm-submitbutton' onClick={editModerationFailedThankyou}>Save</button>
            </div>
        </div>
    );
}
