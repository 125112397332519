import { useState } from 'react';
import { toast } from 'react-toastify';
import { useLoaderStore } from '../../stores/loader.store';
import thankYouService from '../../Services/thank-you.service';

export function EditGift({ giftData, onEditSuccess }) {
    const setShowLoader = useLoaderStore((state) => state.setShowLoader);
    const [email, setEmail] = useState(giftData.createdUserData?.email || "");

    async function editGift() {
        setShowLoader(true);

        try {
            const res = await thankYouService.updateGift(giftData._id, {
                createdUserData: {
                    email: email
                }
            });

            if (res.status === 200) {
                toast.success("Successfully updated gift");
                onEditSuccess();
                return;
            }

            toast.error("Couldn't update gift");
        } catch (err) {
            console.log(err);
            toast.error("Couldn't update gift");
        } finally {
            setShowLoader(false);
        }
    }

    return (
        <div style={{ maxWidth: '600px', maxHeight: '650px', overflowY: "auto" }} className='p-2'>
            <div className="mb-2">
                <div className="d-flex mb-2">
                    <div className='w-50'>
                        <span className="cstm-label">From</span>
                        <p>{giftData.createdUserData?.firstName}</p>
                    </div>
                </div>
            </div>

            <div className="mb-2">
                <label className="cstm-label">Sender Email</label>
                <input
                    type="text"
                    className="cstm-input"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </div>

            <div className="d-flex justify-content-end">
                <button type='button' className='cstm-submitbutton' onClick={editGift}>Save</button>
            </div>
        </div>
    );
}
