import { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Modal } from '../../../shared/components/Modal/Modal';
import { DeleteConfirmationModalWithReason } from '../../../shared/components/Modal/DeleteConfiramtionModalWithReason';
import nonUserService from "../../../Services/non-user.service";
import { ThankyouDeleteReason } from "../../../common/enums/thankyou-delete-reason";
import { DEFAULT_PAGE_SIZE } from '../../../common/constants/pagination.constant';
import { SidebarLayoutContent } from '../../../shared/components/SidebarLayoutContent';
import { Pagination } from '../../shared/Pagination';
import { useLoaderStore } from '../../../stores/loader.store';
import { ConfirmationModal } from '../../../shared/components/Modal/ConfirmationModal';
import { ReleaseModerationFailedThankyou } from "./ReleaseModerationFailedThankyou";
import { EditModerationFailedNonUserThankYou } from "./EditModerationFailedNonUserThankYou";
import { createSearchParamsFromListState, getListStateFromSearchParams } from '../../../utils/list-state.util';
import { formatDateForTable } from '../../../common/utils/date.util';

const MODERATION_FAILED_ACTION = Object.freeze({
    DELETE: "DELETE",
    RELEASE: "RELEASE"
});

export function NonUserModerationFailedThankYouList() {
    const setShowLoader = useLoaderStore((state) => state.setShowLoader);
    const [searchParams, setSearchParams] = useSearchParams();
    const [resultsCount, setResultsCount] = useState(0);
    const state = useMemo(() => getThankYouListStateFromSearchParams(searchParams), [searchParams]);
    const pagination = { pageNumber: state.pageNumber, pageSize: DEFAULT_PAGE_SIZE };
    const [thankYous, setThankYous] = useState([]);
    const [searchText, setSearchText] = useState();
    const [deleteReason, setDeleteReason] = useState(Object.keys(ThankyouDeleteReason)[0]);

    /** @type {[{ team: any, action: string }, React.Dispatch<{ team: any, action: string }>]} */
    const [selectedRow, setSelectedRow] = useState(null);

    useEffect(() => {
        getUserModerationFailedThankyous(pagination.pageNumber, pagination.pageSize);
    }, []);

    useEffect(() => {
        getUserModerationFailedThankyous(pagination.pageNumber, pagination.pageSize);
    }, [searchParams]);

    async function getUserModerationFailedThankyous(pageNumber, pageSize) {

        try {
            const res = await nonUserService.getTeamNonUserThankyous(true, {
                page: pageNumber,
                limit: pageSize,
            });

            if (res.status === 200) {
                setThankYous(res.data.thankyou_data);
                setResultsCount(res.data.state?.data_count);
                // toast.success("Successfully fetched failed thankyous");
                return;
            }

            toast.error("Couldn't get thankyous");
        } catch (err) {
            if (err.error) {
                toast.error(err.error);
                return;
            }
            toast.error("Couldn't get thankyous");
        } finally {
            setShowLoader(false);
        }
    }

    async function handleDeleteThankyou() {
        setShowLoader(true);
        try {
            const deleteThankyou = selectedRow.thankYou;
            const res = await nonUserService.deleteNonUserThankyou(deleteThankyou._id, deleteReason);

            if (res.status === 200) {
                toast.success("Successfully deleted thank you");
                setThankYous((thankYou) => thankYou.filter((t) => t._id !== deleteThankyou._id));
                return;
            }

            toast.error("Couldn't delete thank you");
        } catch (err) {
            console.log(err);
            toast.error("Couldn't delete thank you");
        } finally {
            setSelectedRow(null);
            setShowLoader(false);
        }
    }

    function onPaginationChange(pageNumber) {
        setSearchParams(createThankYouListSearchParams({}, '', pageNumber));
    }

    const handleDeleteInputChange = (e) => {
        const { name, value } = e.target;
        setDeleteReason(value);
    };

    return (
        <SidebarLayoutContent headerTitle='Non User Moderation Failed Thank you List'>
            <div className="card border-0 shadow rounded">
                <div className='d-flex justify-content-end align-items-center p-2'>
                    {/* <div className="mr-2">
                        <i className="fi fi-rr-search cstm-search-ro"></i>
                        <input
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            type="text"
                            className="cstm-input-seacrh"
                            placeholder="Search"
                        />
                    </div> */}
                </div>

                <div className="overflow-auto">
                    <table className="table mb-0 table-center">
                        <thead>
                            <tr className="border-top">
                                <th className="border-bottom w-4 cstm-userheading">Message</th>
                                <th className="border-bottom w-4 cstm-userheading">From</th>
                                <th className="border-bottom w-4 cstm-userheading">To</th>
                                <th className="border-bottom w-4 cstm-userheading">Organization</th>
                                <th className="border-bottom w-4 cstm-userheading">Team</th>
                                <th className="border-bottom w-4 cstm-userheading">Created Date</th>
                                <th className="border-bottom w-4 cstm-userheading">Actions</th>
                            </tr>
                        </thead>

                        <tbody>
                            {thankYous?.map((thankyou, index) => (
                                <tr
                                    key={thankyou._id}
                                    className="cstm-Tabledesign cstm-usertable-design"
                                >
                                    <td>{thankyou.message}</td>
                                    <td>
                                        <div>{thankyou.fromUserData?.firstName}</div>
                                        <div>{thankyou.fromUserData?.email}</div>
                                    </td>
                                    <td>{thankyou.toStaffName}</td>
                                    <td>{thankyou.organization[0]?.name}</td>
                                    <td>{thankyou.team[0]?.name}</td>
                                    <td>{formatDateForTable(thankyou.createdAt)}</td>

                                    <td>
                                        {/* {!thankyou.isActive && */}
                                        <div>
                                            <Link
                                                to={""}
                                                onClick={() => setSelectedRow({ thankYou: thankyou, action: MODERATION_FAILED_ACTION.EDIT })}
                                                className="cstm-eye"
                                            >
                                                <i className="fi fi-rr-pencil"></i>
                                            </Link>


                                            <Link
                                                to={""}
                                                onClick={() => setSelectedRow({ thankYou: thankyou, action: MODERATION_FAILED_ACTION.DELETE })}
                                                class="cstm-delete mrn-rt"
                                            >
                                                <i class="fi fi-rr-trash"></i>
                                            </Link>

                                            {thankyou.isActive ? 'Released' :
                                                <button
                                                    type='button'
                                                    className="cstm-eye"
                                                    onClick={() => setSelectedRow({ thankYou: thankyou, action: MODERATION_FAILED_ACTION.RELEASE })}
                                                    class="cstm-delete mrn-rt"
                                                >
                                                    <i className="fi fi-rr-envelope-plus"></i>

                                                </button>}

                                        </div>
                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </table>

                    {!thankYous?.length && (
                        <div className="NoRecord-cstm">No records found!</div>
                    )}
                </div>
            </div>

            <Pagination
                totalRows={resultsCount}
                currentPage={pagination.pageNumber}
                rowsPerPage={pagination.pageSize}
                onPaginationChange={onPaginationChange}
            />

            {(selectedRow && selectedRow.action === MODERATION_FAILED_ACTION.DELETE) && (
                <DeleteConfirmationModalWithReason
                    text='Are you sure you want to delete this thank you?'
                    confirmBtnText='Delete'
                    type="delete"
                    onConfirm={handleDeleteThankyou}
                    onCancel={() => setSelectedRow(null)}
                    handleDeleteInputChange={handleDeleteInputChange}
                />
            )}

            {(selectedRow && selectedRow.action === MODERATION_FAILED_ACTION.RELEASE) && (
                <Modal
                    show={true}
                    onClose={() => setSelectedRow(null)}
                >
                    <ReleaseModerationFailedThankyou
                        moderationFailedThankyou={selectedRow.thankYou}
                        onReleaseSuccess={() => {
                            setThankYous((current) =>
                                current.filter((data) => data._id !== selectedRow.thankYou?._id)
                            );
                            setSelectedRow(null);
                        }}
                    />
                </Modal>
            )}

            {(selectedRow && selectedRow.action === MODERATION_FAILED_ACTION.EDIT) && (
                <Modal
                    show={true}
                    onClose={() => setSelectedRow(null)}
                >
                    <EditModerationFailedNonUserThankYou
                        moderationFailedThankyou={selectedRow.thankYou}
                        onEditSuccess={() => {
                            setSelectedRow(null);
                            getUserModerationFailedThankyous(pagination.pageNumber, pagination.pageSize);
                        }}
                    />
                </Modal>
            )}
        </SidebarLayoutContent>
    );
}

function createThankYouListSearchParams(filters, searchText, pageNumber) {
    return createSearchParamsFromListState(filters, searchText, pageNumber);
}

function getThankYouListStateFromSearchParams(searchParams) {
    return getListStateFromSearchParams(searchParams);
}
