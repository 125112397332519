// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.clickable {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
}

th .arrow {
    /* Position the arrow to the right of the header */
    transform: translateY(-50%);
    font-size: 12px;
    color: gray;
}`, "",{"version":3,"sources":["webpack://./src/Components/Gift-List/GiftList.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,WAAW;IACX,0BAA0B;AAC9B;;AAEA;IACI,kDAAkD;IAClD,2BAA2B;IAC3B,eAAe;IACf,WAAW;AACf","sourcesContent":[".clickable {\n    cursor: pointer;\n    color: blue;\n    text-decoration: underline;\n}\n\nth .arrow {\n    /* Position the arrow to the right of the header */\n    transform: translateY(-50%);\n    font-size: 12px;\n    color: gray;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
