import { useState } from 'react';
import { toast } from 'react-toastify';
import { useLoaderStore } from '../../../stores/loader.store';
import moderationFailedThankYouService from '../../../Services/moderation-failed-thankyou.service';

export function EditFailedGift({ moderationFailedGift, onEditSuccess }) {
    const setShowLoader = useLoaderStore((state) => state.setShowLoader);
    const [email, setEmail] = useState(moderationFailedGift.createdUserData?.email || "");

    async function editModerationFailedGift() {
        setShowLoader(true);

        try {
            let createdUserData = moderationFailedGift.createdUserData;
            createdUserData.email = email;

            let giftUpdateData = {
                createdUserData
            }

            const res = await moderationFailedThankYouService.updateModerationFailedGift(moderationFailedGift._id, giftUpdateData);

            if (res.status === 200) {
                toast.success("Successfully updated moderation failed gift");
                onEditSuccess();
                return;
            }

            toast.error("Couldn't update moderation failed gift");
        } catch (err) {
            console.log(err);
            toast.error("Couldn't update moderation failed gift");
        } finally {
            setShowLoader(false);
        }
    }

    return (
        <div style={{ maxWidth: '700px', maxHeight: '700px', overflowY: "auto" }} className='p-2'>
            <div className="mb-2">
                <div className="d-flex mb-2">
                    <div className='w-50'>
                        <span className="cstm-label">From</span>
                        <p>{moderationFailedGift.createdUserData?.firstName}</p>
                    </div>
                    {moderationFailedGift.createdUserData?.email && <div className='w-75'>
                        <span className="cstm-label">Email</span>
                        <input
                            type="text"
                            className="cstm-input"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>}
                </div>
                <div className='w-50'>
                    <span className="cstm-label">To</span>
                    <p>{moderationFailedGift.users ? moderationFailedGift.users[0]?.firstName : null}</p>
                </div>
            </div>

            <div className="d-flex justify-content-end">
                <button type='button' className='cstm-submitbutton' onClick={editModerationFailedGift}>Save</button>
            </div>
        </div>
    );
}
