import baseApi from "./base-api";

function releaseModerationFailedThankyou(data) {
    return baseApi.post(`thank-yous/moderationFailedThankyou/release`, data);
}

function releaseModerationFailedGift(data) {
    return baseApi.post(`gifts/moderationFailedGift/release`, data);
}

function updateModerationFailedGift(moderationFailedGiftId, data) {
    return baseApi.put(`gifts/moderationFailedGift/${moderationFailedGiftId}`, data);
}

function updateModerationFailedThankyou(moderationFailedThankyouId, data) {
    return baseApi.put(`thank-yous/moderationFailedThankyou/${moderationFailedThankyouId}`, data);
}

function downloadAsCsv(params = {}) {
    return baseApi.getWithParams("thank-yous/moderationFailedThankyou/download-csv", params);
}

const moderationFailedThankYouService = {
    updateModerationFailedThankyou: updateModerationFailedThankyou,
    downloadAsCsv: downloadAsCsv,
    releaseModerationFailedThankyou: releaseModerationFailedThankyou,
    releaseModerationFailedGift: releaseModerationFailedGift,
    updateModerationFailedGift: updateModerationFailedGift
};

export default moderationFailedThankYouService;
